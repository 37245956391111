const replaceExceptions = [
  { search: '/intern/', replace: '/' },
  { search: '/sportaanbieders/', replace: '/sporten-in-groningen/' },
]

export const replaceUrlExceptions = (url) => {
  let finalUrl = url
  replaceExceptions.forEach((item) => {
    finalUrl = finalUrl.replace(item.search, item.replace)
  })
  return finalUrl
}

export const replaceUrlExceptionsReversed = (url) => {
  let finalUrl = url
  replaceExceptions.forEach((item) => {
    finalUrl = finalUrl.replace(item.replace, item.search)
  })
  return finalUrl
}
