'use client'
import queryString from 'query-string'
import React from 'react'
import { useRouter, usePathname } from 'next/navigation'
import {
  searchFiltersApiClient,
  searchFiltersLocationApiClient,
} from './utils/api/fetchApi'

// My context
const MyContext = React.createContext()

// Context reducer
function contextFiltersReducer(state, action) {
  switch (action.type) {
    case 'merge': {
      return {
        ...state,
        ...action.payload,
      }
    }
    case 'reset': {
      return {}
    }
    case 'set': {
      return action.payload
    }
    default: {
      return state
    }
  }
}

function contextLocationsReducer(state, action) {
  switch (action.type) {
    case 'merge': {
      return {
        ...state,
        ...action.payload,
      }
    }
    case 'reset': {
      return {}
    }
    case 'set': {
      return action.payload
    }
    default: {
      return state
    }
  }
}

function contextClubsReducer(state, action) {
  switch (action.type) {
    case 'merge': {
      return {
        ...state,
        ...action.payload,
      }
    }
    case 'reset': {
      return {}
    }
    case 'set': {
      return action.payload
    }
    default: {
      return state
    }
  }
}

// Use context hook
export const useFilterState = () => {
  const context = React.useContext(MyContext)
  if (context === undefined) {
    throw new Error('UseContext must be used within the Provider')
  }
  return context
}

// Provider
const MyApplicationFilterState = ({ children, defaultState }) => {
  const router = useRouter()
  const pathname = usePathname()
  const [filterState, dispatchFilters] = React.useReducer(
    contextFiltersReducer,
    defaultState
  )
  const [locations, dispatchLocations] = React.useReducer(
    contextLocationsReducer,
    {}
  )
  const [clubs, dispatchClubs] = React.useReducer(contextClubsReducer, {})
  const [isLoading, setIsLoading] = React.useState(false)
  const [filterOpenMobile, setMobileOpen] = React.useState(false)
  const {
    page: statePage,
    order: stateOrder,
    filters: stateFilters,
    category: stateCategory,
    attributes: stateAttributes,
    additionals: stateAdditionals,
    host: stateHost,
    pagetype: statePagetype,
    distance: stateDistance,
    postalCode: statePostalCode,
    mode: stateMode,
  } = filterState

  const setClubContext = (payload) => {
    dispatchClubs({ type: 'merge', payload })
  }
  const setClubs = (payload) => {
    setClubContext({ state: payload })
  }

  const setLocationsContext = (payload) => {
    dispatchLocations({ type: 'merge', payload })
  }
  const setLocations = (payload) => {
    setLocationsContext({ state: payload })
  }

  const setContext = (payload) => {
    dispatchFilters({ type: 'merge', payload })
  }

  const resetContext = () => {
    dispatchFilters({ type: 'reset', payload: {} })
  }

  const overWriteContext = (payload) => {
    dispatchFilters({ type: 'set', payload })
  }

  const setPagetype = (data) => {
    setContext({ pagetype: data })
  }
  const setHost = (data) => {
    setContext({ host: data })
  }

  const setNewQueryUrl = ({
    pagetype = null,
    page = null,
    order = null,
    filters = null,
    category = null,
    attributes = null,
    additionals = null,
    distance = null,
    isInitial = false,
    mode = '',
  }) => {
    // Set the new query in the url based on the new values
    // Slightly different for clubs and locations
    setIsLoading(true)

    const usePagetype = pagetype || statePagetype
    const mapAttrs = (attributes || stateAttributes)?.map((item) => item.id)
    const mapAdditionals = (additionals || stateAdditionals)?.map(
      (item) => item.value
    )
    const stateDistPostalcode = {
      distance: stateDistance,
      postalCode: statePostalCode,
    }
    const useDistance = distance || stateDistPostalcode
    const useMode = mode || stateMode
    const mapFilters = (filters ? filters : stateFilters)?.map(
      (item) => item.slug
    )
    const urlQuery = {
      page: page || statePage,
      order: order || stateOrder,
      attributes: mapAttrs,
      additionals: mapAdditionals,
      mode: useMode,
    } // query params to be set in url
    if (useDistance?.distance) {
      urlQuery.distance = useDistance.distance
      urlQuery.postal_code = useDistance.postalCode
    }

    const selectedFilters = mapFilters
    const selectedCategory =
      category === ''
        ? category?.slug // if empty string is passed down, ignore stateCategory
        : category?.slug || stateCategory?.slug
    const isClubs = pathname.includes("sporten-in-groningen")

    if (!isInitial && isClubs) {
      let baseUrl = window
        ? window.location.protocol +
          '//' +
          window.location.host +
          '/sporten-in-groningen'
        : null

      // Category removed
      if (category !== null && category === '') {
        const newUrl = `${baseUrl}/?${queryString.stringify(urlQuery)}`
        router.push(newUrl, { shallow: true, scroll: false })
      } else if ((category || stateCategory)?.slug) {
        baseUrl = baseUrl + '/' + (category || stateCategory).slug
        if (mapFilters?.length) {
          const filters_string = mapFilters.join('_')
          baseUrl = baseUrl + '/' + filters_string
        }
        const newUrl = `${baseUrl}/?${queryString.stringify(urlQuery)}`
        router.push(newUrl,{ shallow: true, scroll: false })
      } else {
        const newUrl = `${baseUrl}/?${queryString.stringify(urlQuery)}`
        router.push(newUrl, { shallow: true, scroll: false })
      }
    } else if (!isClubs) {
      let locationUrlQuery = {
        ...urlQuery,
        filters: selectedFilters,
        category: selectedCategory,
      }
      const newUrl = `/sportlocaties/?${queryString.stringify(
        locationUrlQuery
      )}`
      router.push(newUrl, { shallow: true, scroll: false })
    }

    setIsLoading(false)
  }

  const getData = async ({
    pagetype = null,
    host = null,
    page = null,
    order = null,
    filters = null,
    category = null,
    attributes = null,
    additionals = null,
    distance = null,
    isInitial = false,
    mode = '',
  }) => {
    setIsLoading(true)
    const useHost = host || stateHost
    const usePagetype = pagetype || statePagetype
    const mapAttrs = (attributes || stateAttributes)?.map((item) => item.id)
    const mapAdditionals = (additionals || stateAdditionals)?.map(
      (item) => item.value
    )
    const stateDistPostalcode = {
      distance: stateDistance,
      postalCode: statePostalCode,
    }
    const useDistance = distance || stateDistPostalcode
    const useMode = mode || stateMode
    const mapFilters = (filters ? filters : stateFilters)?.map(
      (item) => item.slug
    )
    const urlQuery = {
      page: page || statePage,
      order: order || stateOrder,
      attributes: mapAttrs,
      additionals: mapAdditionals,
      mode: useMode,
    } // query params to be set in url
    if (useDistance?.distance) {
      urlQuery.distance = useDistance.distance
      urlQuery.postal_code = useDistance.postalCode
    }
    const selectedFilters = mapFilters
    const selectedCategory =
      category === ''
        ? category?.slug // if empty string is passed down, ignore stateCategory
        : category?.slug || stateCategory?.slug
    const query = {
      ...urlQuery,
      pagetype: usePagetype,
      per_page: 6,
      filters: selectedFilters,
      category: selectedCategory,
    }
    const isClubs = usePagetype == 'club'
    const Client = isClubs
      ? searchFiltersApiClient
      : searchFiltersLocationApiClient

    // Based on whether it is clubs or locations, change the url appropriately
    if (!isInitial && isClubs) {
      let baseUrl = window
        ? window.location.protocol +
          '//' +
          window.location.host +
          '/sporten-in-groningen'
        : null
      if (!isInitial && (category || stateCategory)?.slug) {
        baseUrl = baseUrl + '/' + (category || stateCategory).slug
        if (mapFilters?.length) {
          const filters_string = mapFilters.join('_')
          baseUrl = baseUrl + '/' + filters_string
        }
        const newUrl = `${baseUrl}/?${queryString.stringify(urlQuery)}`
        router.push(newUrl, { shallow: true, scroll: false })
      } else if (!isInitial) {
        const newUrl = `${baseUrl}/?${queryString.stringify(urlQuery)}`
        router.push(newUrl, { shallow: true, scroll: false })
      }
    } else if (!isInitial && !isClubs) {
      let locationUrlQuery = {
        ...urlQuery,
        filters: selectedFilters,
        category: selectedCategory,
      }
      const newUrl = `/sportlocaties/?${queryString.stringify(
        locationUrlQuery
      )}`
      router.push(newUrl, { shallow: true, scroll: false })
    }

    // Fetch Data
    const data = await Client(useHost, query)
    const totalCount = data.search_results.count || 0
    const newClubs = data.search_results.pages
    // The actual page retrieved from the backend. This can differ
    // from input page when page was higher than the number of pages
    const actualPage = data.search_results.number

    const sports_count =
      data.sports_count && Object.keys(data.sports_count)
        ? data.sports_count
        : isClubs
        ? clubs?.state?.sports_count
        : locations?.state?.sports_count

    const state = {
      count: totalCount,
      items: newClubs,
      pages: data.search_results.num_pages,
      dynamic_content: data.dynamic_content,
      categories_count: data.categories_count,
      sports_count: sports_count,
      coordinates: data.coordinates?.all || [],
    }
    if (isClubs) {
      setClubs(state)
    } else if (usePagetype == 'location') {
      setLocations(state)
    }
    if (page !== actualPage) {
      setPage(actualPage)
    }
    setContext({ dynamicContent: data.dynamic_content })
    setContext({ dynamicHeader: data?.header_image })
    setIsLoading(false)
  }

  const getInitialData = ({
    pagetype,
    host,
    filters,
    category,
    additionals = null,
    attributes = null,
    mode = '',
    distance = null,
    page = 1,
  }) => {
    getData({
      page,
      pagetype,
      host,
      additionals,
      attributes,
      distance,
      isInitial: true,
      filters: filters,
      category: category,
      mode: mode,
    })
  }

  const setOrder = (newData) => {
    setContext({ order: newData })
  }

  const setFilters = (newData) => {
    setContext({ filters: newData })
  }

  const setCategory = (newData) => {
    setContext({ category: newData })
  }

  const setDynamicContent = (newData) => {
    setContext({ dynamicContent: newData })
  }

  const setPage = (newData) => {
    setContext({ page: newData })
  }

  const setAttributes = (newData) => {
    setContext({ attributes: newData })
  }

  const setAdditionals = (newData) => {
    setContext({ additionals: newData })
  }

  const setDistancePostalcode = (newData) => {
    setContext({
      distance: newData.distance,
      postalCode: newData.postalCode,
    })
  }

  const setMode = (newData) => {
    setContext({
      mode: newData,
    })
  }

  const handlePageChange = (event, newPage) => {
    setNewQueryUrl({ page: newPage })
    setPage(newPage)
    getData({ page: newPage })
  }

  const handleOrderChange = async (event) => {
    const newOrder = event.target.value
    setNewQueryUrl({ order: newOrder })
    setOrder(newOrder)
    getData({ order: newOrder })
  }

  const handleFiltersChange = (newFilters) => {
    setNewQueryUrl({ filters: newFilters })
    setFilters(newFilters)
  }

  const handleCategoryChange = async (items) => {
    setNewQueryUrl({ category: items })
    setCategory(items)
  }

  const handleAttributeChange = async (items) => {
    setNewQueryUrl({ attributes: items })
    setAttributes(items)
    getData({ attributes: items })
  }
  const handleAdditionalChange = async (items) => {
    setNewQueryUrl({ additionals: items })
    setAdditionals(items)
    getData({ additionals: items })
  }

  const handleDistancePostalcodeChange = async (input) => {
    setNewQueryUrl({ distance: input })
    setDistancePostalcode(input)
    getData({ distance: input })
  }

  const handleModeChange = async (input) => {
    setNewQueryUrl({ mode: input })
    setMode(input)
    getData({ mode: input })
  }

  const handleFilters = (newFilters, category) => {
    setNewQueryUrl({ category: category, filters: newFilters })
    // setFilters(newFilters)
    // setCategory(category)
  }

  const removeFilters = () => {
    setNewQueryUrl({
      category: '',
      filters: [],
      attributes: [],
      additionals: [],
      distance: {},
    })
    setFilters([])
    setCategory(null)
    setAttributes([])
    setAdditionals([])
    setDistancePostalcode({})
  }

  return (
    <MyContext.Provider
      value={{
        filterState,
        locations,
        clubs,
        isLoading,
        getData,
        getInitialData,
        setNewQueryUrl,
        handlePageChange,
        handleOrderChange,
        handleFiltersChange,
        handleCategoryChange,
        handleAttributeChange,
        handleAdditionalChange,
        handleFilters,
        handleDistancePostalcodeChange,
        handleModeChange,
        setPagetype,
        setHost,
        setDynamicContent,
        setCategory,
        setFilters,
        setDistancePostalcode,
        setMode,
        setPage,
        setOrder,
        setAdditionals,
        setAttributes,
        removeFilters,
        filterOpenMobile,
        setMobileOpen,
      }}
    >
      {children}
    </MyContext.Provider>
  )
}

export default MyApplicationFilterState
