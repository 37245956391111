import queryString from 'query-string'
import { replaceUrlExceptions } from '../replaceUrlExceptions'

const base = 'http://backend:8000/api/v2/'
const clientBase = 'http://localhost:8000/api/v2/'
export const postBase = 'http://localhost:8000/'
const searchUrl = postBase + 'search/'
const searchClubsUrl = postBase + 'search_clubs/'
const searchFiltersUrl = postBase + 'search_filters/'
const searchFiltersLocationsUrl = postBase + 'search_locations_filters/'
const searchEventsUrl = postBase + 'search_events/'
const searchNewsUrl = postBase + 'search_news/'
const searchSiteUrl = postBase + 'search_site/'
const validateTitleUrl = postBase + 'api/validate_title/'
const validateEventTitleUrl = postBase + 'api/validate_title_event/'
const validateWebsite = postBase + 'api/validate_website/'

// subsite
const searchSubsiteNewsUrl = postBase + 'subsite_news/'
const searchSubsiteOpeningtimesUrl = postBase + 'api/get_location_events/'

// Redirects
const redirectsUrl = 'http://backend:8000/api/redirects/'

const apiBase = 'http://localhost:8000/api/'

const defaultPostHeaders = (csrftoken, file = null, files = []) => {
  if (file || files?.length > 0) {
    // When sending files, don't set Content-Type - the browser will set it 
    // automatically with the correct boundary parameter
    return {
      Accept: 'application/json',
      'X-CSRFToken': csrftoken,
    }
  }
  return {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-CSRFToken': csrftoken,
  }
}

const myfetch = async (url, options = {}) => {
  const headers = {
    method: 'GET',
    redirect: 'follow',
    next: { revalidate: 600 },
    ...options,
  }

  // fallback for htmlPath needs to be an object
  // otherwise it will be an array for page data
  const fallback = url?.includes('html_path') ? {} : []

  // Return promise with fallback
  return await fetch(url, headers).catch((error) => {
    console.log('backend not ready yet')
    // expecting ENOTFOUND, ECONNREFUSED, UND_ERR_SOCKET
    console.log(error.cause)
    return {
      status: 503,
      json: () => {
        ;fallback
      },
    }
  })
}

export const fetchApi = async (path) => {
  const url = base + path
  const res = await myfetch(url)
  if (res.status === 500) {
    return { items: [] }
  }
  const data = await res.json()

  return data
}

export const fetchApiHtmlPath = async (path, sitename = null) => {
  // Create the api request based on desired path and sitename
  // const isInternal = path.includes("/intern/")
  const fixedPath = replaceUrlExceptions(path)
  let url = `${base}pages/find/?html_path=${fixedPath}`
  if (sitename) {
    url = url + `&site=${sitename}`
  }
  const res = await myfetch(url)
  if (!res.ok) {
    // Return no pagedata as no pagedata is found
    return null
  }
  const data = await res.json()
  return data
}

export const fetchApiClient = async (path, host) => {
  let url
  if (host.includes('localhost')) {
    url = clientBase + path
  } else if (host) {
    url = `${host}/api/v2/${path}`
  }
  const res = await myfetch(url)
  const data = await res.json()

  return data
}

export const searchApiClient = async (query, host) => {
  let url
  if (host.includes('localhost')) {
    url = searchUrl + query
  } else if (host) {
    url = `${host}/api/search/${query}`
  }
  // const url = searchUrl + query
  const res = await myfetch(url)
  const data = await res.json()

  return data
}

export const postApiClient = async (path, host, options) => {
  let url
  if (host.includes('localhost')) {
    url = postBase + path
  } else {
    url = `${host}/api/${path}`
  }
  const res = await myfetch(url, options)
  const data = await res.json()

  return data
}

export const searchClubsApiClient = async (host, query) => {
  const q = query ? '?' + queryString.stringify(query) : ''
  let url
  if (host.includes('localhost')) {
    url = searchClubsUrl + q
  } else if (host) {
    url = `${host}/search_clubs/${q}`
  }
  const res = await myfetch(url)
  const data = await res.json()

  return data
}

export const searchFiltersApiClient = async (host, query) => {
  const q = query ? '?' + queryString.stringify(query) : ''
  let url
  if (host?.includes('localhost')) {
    url = searchFiltersUrl + q
  } else if (host) {
    url = `${host}/search_filters/${q}`
  }
  const res = await myfetch(url)
  const data = await res.json()

  return data
}

export const searchFiltersLocationApiClient = async (host, query) => {
  const q = query ? '?' + queryString.stringify(query) : ''
  let url
  if (host?.includes('localhost')) {
    url = searchFiltersLocationsUrl + q
  } else if (host) {
    url = `${host}/search_locations_filters/${q}`
  }
  const res = await myfetch(url)
  const data = await res.json()

  return data
}

export const searchEventsApiClient = async (host, query) => {
  const q = query ? '?' + queryString.stringify(query) : ''
  let url
  if (host?.includes('localhost')) {
    url = searchEventsUrl + q
  } else if (host) {
    url = `${host}/search_events/${q}`
  }
  const res = await myfetch(url)
  const data = await res.json()

  return data
}

export const searchNewsApiClient = async (host, query) => {
  const q = query ? '?' + queryString.stringify(query) : ''
  let url
  if (host?.includes('localhost')) {
    url = searchNewsUrl + q
  } else if (host) {
    url = `${host}/search_news/${q}`
  }
  const res = await myfetch(url)
  const data = await res.json()

  return data
}

export const searchSiteApiClient = async (host, query) => {
  const q = query ? '?' + queryString.stringify(query) : ''
  let url
  if (host?.includes('localhost')) {
    url = searchSiteUrl + q
  } else if (host) {
    url = `${host}/search_site/${q}`
  }
  const res = await myfetch(url)
  const data = await res.json()

  return data
}

export const getApiData = async (host, type) => {
  let url
  if (host?.includes('localhost')) {
    url = apiBase + type
  } else if (host) {
    url = `${host}/api/${type}`
  }
  const res = await myfetch(url)
  const data = await res.json()

  return data
}

// POST
export const postEventApi = async (
  host,
  path,
  entries = [],
  files = [],
  headerFiles = [],
  csrftoken = '',
  recapthaToken
) => {
  let url
  if (host.includes('localhost')) {
    url = postBase + path
  } else if (host) {
    url = `${host}/${path}`
  }

  const body = {}
  entries.forEach((entry) => {
    if (entry[0] == 'files') {
      const array = array[1] || []
      entry[1].forEach((item, i) => {
        body[`file-${i}`] = item
      })
    } else if (entry[0] == 'header_files') {
      const array = array[1] || []
      entry[1].forEach((item, i) => {
        body[`header_file-${i}`] = item
      })
    } else if (entry[0] != 'checkbox') {
      body[entry[0]] = entry[1]
    }
  })
  body['files'] = files
  body['header_files'] = headerFiles
  body['recaptcha'] = recapthaToken

  const res = await myfetch(url, {
    method: 'post',
    body: JSON.stringify(body),
    headers: defaultPostHeaders(csrftoken),
    credentials: 'include',
  })
  const data = await res.json()

  return data
}

export const postFormApi = async (host, path, entries = [], csrftoken = '') => {
  let url
  if (host.includes('localhost')) {
    url = postBase + path
  } else if (host) {
    url = `${host}/${path}`
  }

  const body = {}
  entries.forEach((entry) => {
    if (entry[0] != 'checkbox') {
      body[entry[0]] = entry[1]
    }
  })

  const res = await myfetch(url, {
    method: 'post',
    body: JSON.stringify(body),
    headers: defaultPostHeaders(csrftoken),
    credentials: 'include',
  })

  const data = await res.json()
  return data
}

export const postClubApi = async (
  host,
  path,
  entries = [],
  logo = {},
  header_image = {},
  csrftoken = '',
  recapthaToken = ''
) => {
  let url
  if (host.includes('localhost')) {
    url = postBase + path
  } else if (host) {
    url = `${host}/${path}`
  }

  const body = {}
  entries.forEach((entry) => {
    if (entry[0] != 'checkbox') {
      body[entry[0]] = entry[1]
    }
  })
  body['logo'] = logo
  body['header_image'] = header_image
  body['recaptcha'] = recapthaToken

  const res = await myfetch(url, {
    method: 'post',
    body: JSON.stringify(body),
    headers: defaultPostHeaders(csrftoken),
    credentials: 'include',
  })
  const data = await res.json()

  return data
}

// Subsites
export const subsiteNewsApiClient = async (host, query) => {
  const q = query ? '?' + queryString.stringify(query) : ''
  let url
  if (host?.includes('localhost')) {
    url = searchSubsiteNewsUrl + q
  } else if (host) {
    url = `${host}/subsite_news/${q}`
  }
  const res = await myfetch(url)
  const data = await res.json()

  return data
}

export const subsiteOpeningTimesApiClient = async (host, query) => {
  const q = query ? '?' + queryString.stringify(query) : ''
  let url
  if (host?.includes('localhost')) {
    url = searchSubsiteOpeningtimesUrl + q
  } else if (host) {
    url = `${host}/api/get_location_events/${q}`
  }
  const res = await myfetch(url)
  const data = await res.json()

  return data
}

export const fetchRedirectApi = async (resolvedUrl) => {
  if (!resolvedUrl) return
  const url = redirectsUrl + `?resolved_url=${resolvedUrl}`
  const res = await myfetch(url)
  const data = await res.json()

  return data
}

export const postCustomFormApi = async (
  host,
  path,
  entries = [],
  id,
  email = '',
  csrftoken = '',
  recapthaToken = '',
  formData = null,
  file = null,
  files = []
) => {
  let url
  if (host.includes('localhost')) {
    url = postBase + path
  } else if (host) {
    url = `${host}/${path}`
  }

  let useBody
  if (file || files?.length > 0) {
    if (file) {
      formData.append('file', file)
    }
    if (files?.length > 0) {
      files.forEach((file, index) => {
        formData.append("file", file)
      })
    }
    formData.append('id', id)
    formData.append('email_to', email)
    formData.append('recaptcha', recapthaToken)
    useBody = formData
  } else {
    const body = {
      id: id,
      email_to: email,
    }
    entries.forEach((entry) => {
      body[entry[0]] = entry[1]
    })

    body['recaptcha'] = recapthaToken
    useBody = JSON.stringify(body)
  }

  const res = await myfetch(url, {
    method: 'post',
    body: useBody,
    headers: defaultPostHeaders(csrftoken, file, files),
    credentials: 'include',
  })

  const data = await res.json()
  return data
}

export const validateTitleApi = async (title, host) => {
  let url
  const q = `?title=${title}`
  if (host?.includes('localhost')) {
    url = validateTitleUrl + q
  } else if (host) {
    url = `${host}/api/validate_title/${q}`
  }
  const res = await myfetch(url)
  const data = await res.json()

  return data
}

export const validateTitleEventApi = async (title, date, host) => {
  let url
  const q = `?title=${title}&date=${date}`
  if (host?.includes('localhost')) {
    url = validateEventTitleUrl + q
  } else if (host) {
    url = `${host}/api/validate_title_event/${q}`
  }
  const res = await myfetch(url)
  const data = await res.json()

  return data
}

export const validateWebsiteApi = async (website, host) => {
  let url
  const q = `?website=${website}`
  if (host?.includes('localhost')) {
    url = validateWebsite + q
  } else if (host) {
    url = `${host}/api/validate_website/${q}`
  }
  const res = await myfetch(url)
  const data = await res.json()

  return data
}
